<template>
    <li>
        <p>Type: <span>{{request.type}}</span></p>
        <p>Customer Name: <span> {{request.firstName}} {{request.lastName}}</span></p>
        <p>Customer's Contacts: <span>{{request.phoneNumber}}</span></p>
        <p>Property Owner: <span>{{request.ownerName}}</span></p>
        <p>Owner's contacts: <span>{{request.ownerPhoneNumber}}</span></p>
        <p>Property Location: <span>{{request.propertyLocation}}</span></p>
        <p>Building Name: <span>{{request.buildingName}}</span></p>
        <p>Apartment Number: <span>{{request.apartmentNumber}}</span></p>
        <p>Date the request was sent: <span>{{ request.date }}</span></p>
        <router-link :to="rentalDetail">See property</router-link>
    </li>
</template>

<script>
export default {
    props: ['request'],
    computed: {
        emailLink() {
            return 'mailto:' + this.request.emailAddress
        },
        requestOwner() {
          console.log(this.$store.getters["rentals/properties"].filter(property => property.id === this.request.rentalId))
            return this.$store.getters["rentals/properties"].filter(property => property.id === this.request.rentalId)
        },
      rentalDetail() {
        return "/rentals/" + this.request.rentalId;
    },
    }
}
</script>

<style scoped>
span {
  font-weight: bold;
}
li {
  margin: 1rem 0;
  border: 1px solid #ccc;
  padding: 1rem;
}

a {
  color: #3d008d;
  text-decoration: none;
  font-weight: bold;
}

a:hover,
a:active {
  color: #8d007a;
}

p {
  margin: 0.5rem 0 0 0;
}
</style>